// Stylesheet by A4 Agentur AG

// set base font size to 16px
html { 
	font-size: 16px;
	font-weight: 400;
	-webkit-text-size-adjust: 16px; 
	-ms-text-size-adjust: 16px; 
}

// Template Remove A11Y Outlines
* { outline:none; }

p {
	font-weight: 400;
}

//  Table of Contents:
//
//   1. Global
//   2. Top Bar
//   3. Title Bar
//   4. Off Canvas
//   5. Header
//   6. Sections
//   7. Button
//   8. Callout
//   9. Teamadress
//  10. Contact Form
//  11. 
//  12. 
//  13. 
//  14. 
//  15. Footer
//

// 1. Global
// ---------





// 1. Top Bar
// ---------

//Screen top-bar (fixed)

$topbar-height: 120px;

.top-bar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    left: 0;
    height: $topbar-height;
	padding: 0; //adjust vOffset:0 in HTML MARKUP dropdown-pane data-options
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1)

}

/*
.top-bar .title-logo {
	position: absolute; 
	z-index: 100; 
	top: 0;
}

.top-bar .title-logo .logo {
    height: $topbar-height - 40px;
    margin: 20px 0 0 0;
    width: 100%;
}
*/

.top-bar .title-logo {
	position: absolute; 
	z-index: 100; 
	top: 0;
    height: $topbar-height - 40px;
    margin: 20px 0 0 0;
}

.top-bar .title-logo .logo {
    height: $topbar-height - 40px;
}

.top-space-screen {
	height: $topbar-height;
}

.top-bar li a { 
	line-height: $topbar-height;
	padding-top: 0;
	padding-bottom: 0;
	text-transform: uppercase;
}

.top-bar li a:hover { 
	color: $secondary-color;
}

.top-bar li li a { 
	line-height: inherit;
	text-transform: none;
}

//Dropdown

.menu.dropdown	{
	margin: 0 -15px 0 0;
}

.mega-dropdown {
  
  .mega {
    width: 100%;
    margin: 0 auto !important;
    left: 0% !important;
    right: 0% !important;
	border: none;
	background: $primary-color;
  }
  
   //full-width megamenu
  .wrapper {
    //max-width: $grid-row-width;
    margin: 0 auto !important;
    left: 0% !important;
    right: 0% !important;
    padding: 0 15px;
  }
  
  .menu {
	background: none;  
	margin: 0 -15px 0 0;
	display: inline-block
  }

}


@media screen and (max-width: $grid-row-width) {
	.mega-dropdown {
	  .wrapper {
	    padding: 0 5px 0 0;
	  }
	}
}

.mega-dropdown > a {
	position: relative;
}

.mega-dropdown:hover > a:after {
	top: $topbar-height - 14px;
	left: 50%;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	margin-left: -25px;
	border-style: solid;
	border-width: 0 25px 15px 25px;
	border-color: transparent transparent $primary-color transparent;
}

//dropdown secondlevel

.dropdown-pane {
	padding: 10px;
}

.dropdown-pane li > a {
	background: none;
}

.dropdown-pane li > a {
	color: $white;
}


// 3. Title Bar
// ------------

$titlebar-height: 70px;

.title-bar {
    position: fixed;
    width: 100%;
    height: $titlebar-height;
	padding: 0 15px 0 15px;
    z-index: 99;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1)

}

.title-bar .menu-icon {
	margin: 27px 0 27px 0;
}

.title-bar .title-logo {
	position: absolute; 
	z-index: 100; 
	top: 0; 
	margin: 7px 0 0 40px;
	height: $titlebar-height - 14px; 
}

.title-bar .title-logo .logo {
	height: $titlebar-height - 14px; 
}

.top-space-mobile {
	height: $titlebar-height;
}

// 4. Off Canvas
// -------------

.off-canvas {
	height: 100%;
	background: $primary-color;
}

.off-canvas .nested {
	margin: 0;
}

.off-canvas li a {
	color: $white;
	background: $primary-color;
	border-bottom: 1px solid $white;
}

.off-canvas .nested li a {
	color: $primary-color;
	background: $white;
	border-bottom: 1px solid $light-gray;
}

// 5. Header
// -------------

.header {
	display: table;
	width: 100%;
	background-position: center center;	
	background-size: cover;	
	height: 200px;
}

.header.large {
	height: 200px;
}

.header .caption {
    display: table-cell;
    vertical-align: middle;
}

.header .caption.bottom-right {
    vertical-align: bottom;
    text-align: right;
    padding: 0 0 20px 0;
}

.header h1 {
	text-transform: uppercase;
	font-weight: 600;
	color: $white;
	text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
	line-height: 1.2;
}

@include breakpoint(medium) { 
	.header {
		height: 250px;
	}
	.header.tiny {
		height: 250px;
	}
	.header.large {
		height: 300px;
	}
}

@include breakpoint(large) { 
	.header {
		height: 300px;
	}
	.header.tiny {
		height: 250px;
	}
	.header.large {
		height: 400px;
	}
}

@include breakpoint(xxlarge) { 
	.header {
		height: 400px;
	}
	.header.tiny {
		height: 300px;
	}
	.header.large {
		height: 450px;
	}
	.header h1 {
		font-size: rem-calc(60);
	}
}


// 6. Section
// -------------

div.anchor {
    display: block;
    position: relative;
    top: $titlebar-height*-1;
    visibility: hidden;
}

@include breakpoint(large) { 
	div.anchor {
	    top: $topbar-height*-1;
	}
}

.section {
	position: relative;
	padding: 50px 20px 50px 20px;
}

.section:after {
	bottom: -15px;
	left: 50%;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	margin-left: -25px;
	border-style: solid;
	border-width: 15px 25px 0 25px;
	border-color: $white transparent transparent transparent;
}

.section:nth-child(-n+1):after {
    z-index: 1;
}
.section:nth-child(-n+2):after {
    z-index: 2;
}
.section:nth-child(-n+3):after {
    z-index: 3;
}
.section:nth-child(-n+4):after {
    z-index: 4;
}
.section:nth-child(-n+5):after {
    z-index: 5;
}
.section:nth-child(-n+6):after {
    z-index: 6;
}
.section:nth-child(-n+7):after {
    z-index: 7;
}
.section:nth-child(-n+8):after {
    z-index: 8;
}
.section:nth-child(-n+9):after {
    z-index: 9;
}
.section:nth-child(-n+10):after {
    z-index: 10;
}

.section.light-yellow:after {
	border-color: $bg-light-yellow transparent transparent transparent;
}

.section.light-yellow:last-child:after {
	border-color: transparent transparent transparent transparent;
}

.section.light-blue:after {
	border-color: $bg-light-blue transparent transparent transparent;
}

.section.light-blue:last-child:after {
	border-color: transparent transparent transparent transparent;
}

.section.light-yellow {
	background: $bg-light-yellow;
}

.section.light-blue {
	background: $bg-light-blue;
}

.section .divider {
	width: 100px;
	height: 3px;
	background: $secondary-color;
	margin: 30px auto;
}


//Section color
.section h1.blue,
.section h2.blue,
.section h3.blue,
.section h4.blue,
.section h5.blue,
.section h6.blue {
	color: $dark-blue;
}

.section h1.brown,
.section h2.brown,
.section h3.brown,
.section h4.brown,
.section h5.brown,
.section h6.brown {
	color: $secondary-color;
}

.section ul {
	list-style: none;
}

.section ul li:before{
	position: absolute;
	content: ">";
	margin-left: -20px;
}

//Section text-centered

.section .text-centered {
	text-align: center;
}

.section .text-centered p {
	max-width: 800px;
	margin: 0 auto 20px auto;
}

.section .text-centered h2 {
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
	margin: 25px 0 40px 0;
	line-height: 1.2;
}


//Section quote

.section .quote h3 {
	margin-left: 16px;
	max-width: 700px;
}

.section.light-blue .quote h3 {
	color: $dark-blue;
}

.section.light-yellow .quote h3 {
	color: $secondary-color;
}

.section.text-blue {
	color: $dark-blue;
}

.section.text-blue h3,
.section.text-blue h4,
.section.text-blue h5,
.section.text-blue h6 {
	color: $dark-blue;
}

.section .quote h3:before {
	position: absolute;
	content: "/";
	margin-left: -30px;
	margin-top: -20px;
	font-weight: 100;
	font-size: rem-calc(60);
	color: $secondary-color;
}

@include breakpoint(medium) {
	.section .quote h3 {
		margin-left: 50px;
	}
	.section .quote h3:before {
		margin-left: -50px;
		margin-top: -30px;
		font-size: rem-calc(100);
	}
}

@include breakpoint(large) {
	.section .quote h3 {
		margin-left: 80px;
	}
	.section .quote h3:before {
		margin-left: -80px;
		margin-top: -45px;
		font-size: rem-calc(140);
	}
}


//Section Team

.section .blockgrid.team {
	max-width: 700px;
	margin: 0 auto;
}

.section .blockgrid.team .content {
	margin: 0 0 30px 0;
}

.section .blockgrid.team .content img {
	margin: 0 0 20px 0;
}

.section .blockgrid.team .content h5 {
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
}

.section .blockgrid.team .content.blue,
.section .blockgrid.team .content.blue h5,
.section .blockgrid.team .content.blue a:not(.button) {
	color: $dark-blue;
}

//Section Teamdetail

.section .blockgrid.teamdetail {
	max-width: 700px;
	margin: 0 auto;
}

.section .blockgrid.teamdetail .content {
	//margin: 0 0 30px 0;
}

.section .blockgrid.teamdetail .content.blue {
	background: $bg-light-blue;
}

.section .blockgrid.teamdetail .content.brown {
	background: $bg-light-yellow;
}

.section .blockgrid.teamdetail .content img {
	margin: 0 0 20px 0;
}

.section .blockgrid.teamdetail .content.blue .divider {
	background: $dark-blue;
	margin: 0 auto;
}

.section .blockgrid.teamdetail .content.brown .divider {
	background: $primary-color;
	margin: 0 auto;
}

.section .blockgrid.teamdetail .content h5 {
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
}

.section .blockgrid.teamdetail .content.blue,
.section .blockgrid.teamdetail .content.blue h5,
.section .blockgrid.teamdetail .content.blue h6,
.section .blockgrid.teamdetail .content.blue a {
	color: $dark-blue;
}

.section .blockgrid.teamdetail .text {
	text-align: center;
	padding: 0 15px 20px 15px;
}
.section .blockgrid.teamdetail .copy {
	padding: 0 15px 20px 15px;
}


@include breakpoint(medium) {
	.section .blockgrid.teamdetail .content h6 {
		font-size: rem-calc(18);
	}
}

//Section Blockgrid aktuell

.section .blockgrid.aktuell .column {
	margin: 0 0 50px 0;
}

.section .blockgrid.aktuell .content {
	margin: 0 0 20px 0;
}

.section .blockgrid.aktuell .content h5 {
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
}

//Section Blockgrid kompetenzen

.section .blockgrid.kompetenzen {
	margin: 50px 0 0 0;
}

.section .blockgrid.kompetenzen .column {
	margin: 0 0 15px 0;
}

.section .blockgrid.kompetenzen .content {
	margin: 0 0 20px 0;
}

.section .blockgrid.kompetenzen .content h3 {
	text-transform: uppercase;
	font-weight: 600;
	line-height: 1.2;
	letter-spacing: 1px;
	color: $secondary-color;
}

.section .blockgrid.kompetenzen .content .divider {
	width: 100px;
	height: 1px;
	background: $white;
	margin: 20px auto;
}

@include breakpoint(medium) {
	.section .blockgrid.kompetenzen .content h3 {
		font-size: rem-calc(25);
	}
}

@include breakpoint(large) {
	.section .blockgrid.kompetenzen .content h3 {
		font-size: rem-calc(30);
	}
	.section .blockgrid.kompetenzen .content p {
		font-size: rem-calc(20);
	}
}

//Section Blockgrid dienstleistungen

.section .blockgrid.dienstleistungen {
	margin: 30px 0 0 0;
}

.section .blockgrid.dienstleistungen .column {
	margin: 0 0 50px 0;
}

.section .blockgrid.dienstleistungen .content {
	margin: 0 0 20px 0;
}

@include breakpoint(medium) {
	.section .blockgrid.dienstleistungen .content {
		padding: 0 30px 0 0;
	}
}

.section .blockgrid.dienstleistungen .content h5 {
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
}

.section .blockgrid.dienstleistungen .content h6 {
	font-size: rem-calc(18);
	margin: 0 0 20px 0;
}

@include breakpoint(large) {
	.section .blockgrid.dienstleistungen .content h6 {
		font-size: rem-calc(24);
	}
}

.section .blockgrid.dienstleistungen.center .content h5 {
	text-align: center;
}

.section .blockgrid.dienstleistungen.center .content h6 {
	text-align: center;
}

//Section Referenzen

.section.referenzen .testimonials .quote {
	text-align: center;
	margin: 0 0 100px 0;
}

.section.referenzen .testimonials .quote h5 {
	max-width: 500px;
	margin: 0 auto;
	color: $secondary-color;
}

.section.referenzen .referencelist .block {
	margin: 0 0 50px 0;
}
.section.referenzen .referencelist .block h5 {
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
}

// Section Geschichte

.section .geschichte {
	max-width: 800px;
	margin: 0 auto;
}



// 7. Button
// -------------

.button {
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
}

.button.brown {
	background: $white;
	border: 1px solid $primary-color;
	color: $primary-color;
}

.button.brown:hover {
	background: $primary-color;
	border: 1px solid $primary-color;
	color: $white;
}

.button.blue {
	background: $white;
	border: 1px solid $dark-blue;
	color: $dark-blue;
}

.button.blue:hover {
	background: $dark-blue;
	border: 1px solid $dark-blue;
	color: $white;
}

.button.lightbrown {
	background: $secondary-color;
	border: 1px solid $secondary-color;
	color: $white;
}

.button.lightbrown:hover {
	background: $white;
	border: 1px solid $secondary-color;
	color: $secondary-color;
}


// 8. Callout
// -------------


.callout.darkblue {
	border: none;
	background: $dark-blue;
	color: $white;
	text-align: center;
	padding: 30px 20px 40px 20px;
}

// 9. Teamadress
// --------------

.teamadress {
	//max-width: 1200px;
	//margin: 20px auto;	
}

.teamadress img {
	margin: 0 0 40px 0;	
}

.teamadress .block {
	margin: 0 0 40px 0;	
}

.teamadress .divider {
	margin: 20px 0;	
}

.teamadress h5,
.teamadress h6 {
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
	margin: 0;
}

.teamadress .block.blue,
.teamadress .block.blue a,
.teamadress .block.blue h5 {
	color: $dark-blue;
}


// 10. Contactform
// ---------------

.contact #blankDiv {
	display: none;
}

.contact {
	max-width: 600px;
	margin: 0 auto;	
}

.contact [type='text'],
.contact [type='password'],
.contact [type='date'],
.contact [type='datetime'],
.contact [type='datetime-local'],
.contact [type='month'],
.contact [type='week'],
.contact [type='email'],
.contact [type='number'],
.contact [type='search'],
.contact [type='tel'],
.contact [type='time'],
.contact [type='url'],
.contact [type='color'],
textarea {
  margin: 0;
}

.contact p.small {
	font-size: rem-calc(11);
	margin: 10px 0 0 0;
}

.contact .radio {
	background: $white;
}

.contact .radio,
.contact .checkbox {
	margin: 0 0 1rem 0;
	padding: 13px 0 0 10px;
    height: 3em;
    border: 1px solid $primary-color;
}

.contact .radio.error,
.contact .checkbox.error {
    border: 1px solid red;
}

.contact .radio label,
.contact .checkbox label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding: 0 0 0 24px;
	line-height: 20px;
	color: $primary-color;
	font-size: 1em,
}

.contact .radio input[type=radio],
.contact .checkbox input[type=checkbox] {
    display: none;
}

.contact .radio label:before,
.contact .checkbox label:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    bottom: 2px;
    border: 1px solid $primary-color;
}

.contact .radio label.error.contact:before,
.contact .checkbox label.error.contact:before {
    border: 1px solid red;
}

.contact .radio input[type=radio]:checked+label:before,
.contact .checkbox input[type=checkbox]:checked+label:before{
    content: "\2713";
    color: $black;
    font-size: 14px;
    text-align: center;
    line-height: 16px;
}

// Set Defaults for Adaptive Placeholder 
// See all available variables at https://github.com/zellwk/adaptivePlaceholders
$adaptive-placeholder: (
  height: 3em,
  margin: 1em,
  border: 1px,
  border-radius: 0,
  font-size: 1em,
  border-color: $primary-color,
  label-color: $primary-color,
  active-color: $primary-color,
  valid-color: $primary-color,
  placeholder-background-color: white,
  textarea: false
);

// Set Adaptive Placeholder to use with Textareas
// Note the use of textarea key
$textarea-placeholder: (
  height: 18em,
  border-radius: 0,
  textarea: true,
);


// Calling adaptive placeholder for input
.contact input[type='email'],
.contact input[type='text'] {
  @include adaptive-placeholder();
  width: 100%;
  margin-top: 20px;
}

// calling adaptive placeholder for textarea
.contact textarea {
  @include adaptive-placeholder($textarea-placeholder);
  width: 100%;
}

// error
.contact textarea.error,
.contact input[type='email'].error,
.contact input[type='text'].error {
  border-color: red,
}

// error re enter value
.contact textarea:valid.error,
.contact input[type='email']:valid.error,
.contact input[type='text']:valid.error {
  border-color: $primary-color,
}



// 15. Footer
// -------------

.footer {
	position: relative;
	width: 100%;
	background: $primary-color;
	text-align: center;
	color: $white;
	padding: 0 0 30px 0;
	z-index: 100;
}

.footer:before {
	top: -14px;
	left: 50%;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	margin-left: -25px;
	border-style: solid;
	border-width: 0 25px 15px 25px;
	border-color:  transparent transparent $primary-color transparent;
}

.footer a.backtotop {
	position: absolute;
	top: -14px;
	width: 80px;
	height: 40px;
	margin-left: -40px;
	padding: 15px 0 0 0;
	font-size: 13px;
	color: $white;
	text-transform: uppercase;
	text-decoration: underline; 
}

.footer a {
	color: $white;
	text-decoration: none; 
}

.footer .nav {
	margin: 40px 0 0 0;
}

.footer .nav ul {
	display: inline-block;
	margin: 0 0 10px 0;
}

.footer .nav ul li a {
	text-transform: uppercase;
	color: $secondary-color;
}






/* ======================= MediaQueries ======================== */

.breakpoint-indicator {
	position: fixed;
	right:0;
	padding: 0 15px;
	font-size: rem-calc(11);
	z-index: 100;
	background: $light-gray;
	//display: none;
}

.breakpoint-indicator:before {
	content:'none';
}

@include breakpoint(small only) { 
// Only affects small screens

	.breakpoint-indicator:before {
		content:'small';
	}
}

@include breakpoint(small) { 
// Only affects small screens and larger
}

@include breakpoint(medium down) {
// Only affects medium screens and smaller
}

@include breakpoint(medium only) { 
// Only affects medium screens

	.breakpoint-indicator:before {
		content:'medium';
	}
}

@include breakpoint(medium) { 
// Only affects medium screens and larger
}

@include breakpoint(large down) {
// Only affects large screens and smaller
}

@include breakpoint(large only) { 
// Only affects large screens

	.breakpoint-indicator:before {
		content:'large';
	}
}

@include breakpoint(large) { 
// Only affects large screens and larger
}

@include breakpoint(xlarge down) {
// Only affects xlarge screens and smaller
}

@include breakpoint(xlarge only) { 
// Only affects xlarge screens

	.breakpoint-indicator:before {
		content:'xlarge';
	}
}

@include breakpoint(xlarge) { 
// Only affects xlarge screens and larger
}

@include breakpoint(xxlarge down) {
// Only affects xxlarge screens and smaller
}

@include breakpoint(xxlarge only) { 
// Only affects xxlarge screens

	.breakpoint-indicator:before {
		content:'xxlarge';
	}
}

@include breakpoint(xxlarge) { 
// Only affects xxlarge screens and larger
}

@include breakpoint(landscape) {
// for landscape-oriented devices only
}

@include breakpoint(retina) {
//for high-resolution displays only
}


/* ======================= /MediaQueries ======================== */

