// Stylesheet by A4 Agentur AG

//  Table of Contents:
//
//   1. 
//   2. 
//   3. 
//   4. 
//   5. 
//   6. 
//   7. 
//   8. 
//   9. 
//  10. 
//  11. 
//  12. 
//  13. 
//  14. 
//  15. 


// 1. 
// ------------


.editmode {
	font-size: rem-calc(14);
	color: $black;
}

.editmode label {
	margin: 15px 0 0 0;
	color: $dark-gray;
}

.editmode label:first-child {
	margin: 0;
}

.editmode .accordion-title {
	padding: 10px 15px;
	color: $black;
}

.editmode .accordion-content {
	color: $black;
}

.editmode .accordion-content a {
	color: $black;
}

